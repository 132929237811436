@font-face {
  font-family: 'Inter';
  src: url('{{ site.BaseURL }}/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('{{ site.BaseURL }}/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('{{ site.BaseURL }}/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}
