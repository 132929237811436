.blog-content {
  max-width: 900px;

  .highlight {
    pre {
      padding: 16px;
      border-radius: 8px;
    }
  }
}
