
$font-family-base: "Inter", sans-serif;

// $theme-colors-rgb: "light";
$primary: #7C76BB;
$secondary: #27A3FF29;
$danger: #FF87B0;
$light: #FFFFFF;

// scss-docs-start import-stack
// Configuration
@import "bootstrap/functions";
// $sizes: ();
@import "bootstrap/variables";
@import "bootstrap/maps";
@import "bootstrap/mixins";
@import "bootstrap/utilities";
@import "bootstrap/helpers";
@import "bootstrap/utilities/api";
// Layout & components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/accordion";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/toasts";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/spinners";
@import "bootstrap/offcanvas";
@import "bootstrap/placeholders";

// Helpers

// Utilities
// scss-docs-end import-stack
//

@import "shared/fonts";
@import "shared/utilities";

@import "shared/header";
@import "shared/footer";

@import "sections/hero";
@import "sections/brands";
@import "sections/portfolio";
@import "sections/services";
@import "sections/testimonials";
@import "sections/cta";

@import "sections/blog-list";
@import "sections/blog-single";
